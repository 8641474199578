header {
    justify-content: space-between;
}

header button {
    margin: 10px;
}

header svg {
    height: 50px;
    width: 50px
}

.topright {
    text-align: end;
}

#menubutton {
    font-size: xx-large;
    position: absolute;
    top: 10px;
    left: 30px;
    cursor: pointer;
}