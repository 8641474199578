.menu {
    flex-direction: column;
    width: fit-content;
    margin: 10px;
}

.collection {
    flex-direction: column;
    white-space: nowrap;
}

.entry {
    line-height: 2em;
}

.delete {
    margin-right: 10px;
}

.addButton {
    justify-content: space-around;
    margin: 10px;
}