.container {
    display: flex;
    
}

.dashboard {
    flex-direction: column;
    height: 100vh;
}

.main {
    flex-grow: 1;
}

.tiles {
    justify-content: space-around;
    flex-wrap: wrap;
}

.spacer {
    width: 20%;
}