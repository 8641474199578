.landingpage {
    justify-content: space-around;
}

.infobox {
    justify-content: space-around;
}

.center {
    flex-direction: column;
    align-items: center;
}

.landingpage button {
    margin: 20px;
}