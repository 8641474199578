h3 {
    width: 65%;
}

#collection-image {
    background-color: white;
    max-width: 30%;
    max-height: 100px;
    width: auto;
    height: auto;
    position: absolute;
    top: 10px;
    right: 10px;
}

.tile {
    width: 300px;
    height:fit-content;
    margin: 10px;
    padding: 10px;
    /* background-color: rgb(32, 32, 32); */
    position: relative;
    outline-style: double;
}

#logobox {
    position: absolute;
    bottom: 10px;
    right: 10px;
}